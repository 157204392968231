export function pagination({maxPages, contextPages = 5, pageParam = 'page'}) {
    return {
        currentPage: 0,

        init() {
            let url_params = new URLSearchParams(window.location.search);
            this.currentPage = Number(url_params.get(pageParam) ?? 1);
        },

        getPageHref(number) {
            let url = new URL(window.location.href);
            url.searchParams.set(pageParam, number);
            return url.toString();
        },

        pageNumbers() {
            let page_numbers = [];

            const MIN_PAGES = 1;
            const BETWEEN = "...";

            let first_page = Math.max(this.currentPage - contextPages, MIN_PAGES);
            let last_page  = Math.min(this.currentPage + contextPages, maxPages);

            if (first_page > MIN_PAGES) {
                page_numbers.push(MIN_PAGES);
                if (first_page - 1 != MIN_PAGES) {
                    page_numbers.push(BETWEEN);
                }
            }

            for (; first_page<=last_page; first_page++) {
                page_numbers.push(first_page);
            }

            if (last_page < maxPages) {
                if (last_page + 1 != maxPages) {
                    page_numbers.push(BETWEEN);
                }
                page_numbers.push(maxPages);
            }

            return page_numbers;
        },
    }
}
