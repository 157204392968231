export default function ({userId}) {
    return {
        userId: userId,
        sendUserDetailsEmail() {
            if (confirm('Zeker?')) {
                fetch('/ajax/admin/sendUserDetailsEmail/' + this.userId)
                    .then(response => response.json())
                    .then(json => {
                        if (json.success) {
                            this.$dispatch('add-flash-message', {
                                message: 'Er is een e-mail verstuurd naar de gebruiker met zijn/haar gegevens',
                                type: 'success',
                                permanent: true
                            });
                        } else {
                            this.$dispatch('add-flash-message', {
                                message: 'Er is iets misgegaan tijdens het versturen van de gebruiker gegevens.',
                                type: 'error',
                                permanent: true
                            });
                        }
                    })
                    .catch((e) => {
                        this.$dispatch('add-flash-message', {
                            message: 'Er is iets misgegaan tijdens het versturen van de gebruiker gegevens.',
                            type: 'error',
                            permanent: true
                        });
                        throw new Error(e.status);
                        return {};
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        unlockUser() {
            const username = document.getElementById('unlockUserButton').getAttribute('data-username');

            fetch('/ajax/admin/unlockUser/' + username)
                .then(response => response.json())
                    .then(json => {
                        if (json.success) {

                        } else {
                            this.$dispatch('add-flash-message', {
                                message: 'Verkeerde loginnaam.',
                                type: 'error',
                                permanent: true
                            });
                        }
                    })
                    .catch((e) => {
                        this.$dispatch('add-flash-message', {
                            message: 'Er is iets misgegaan.',
                            type: 'error',
                            permanent: true
                        });
                        throw new Error(e.status);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
        }
    }
}