import appliancePicker from './components/_appliancePicker.js'
import applianceInspections from './components/_applianceInspections.js'
import searchableDropdown from './components/_searchableDropdown.js'
import adminUserLocations from './components/_adminUserLocations.js'
import user from './components/_user.js'
import passwordToggle from './components/_passwordToggle.js'
import flash from './components/_flash.js'
import alpineTable from './components/_alpineTable.js'
import datePicker from './components/_datePicker.js'
import {tabs} from './components/_tabs.js'
import {productGallery} from './components/_productGallery.js'
import {userLocations} from './components/_userLocations.js'
import {multiSelect} from './components/_multiSelect.js'
import {userLocationStore} from './stores/_userLocationStore.js'
import {locations} from './components/_locations.js'
import {pagination} from './components/_pagination.js'

/**
 * Alpine stores
 */
window.Alpine.store('userLocationStore', userLocationStore())

/**
 * Alpine components
 */
window.Alpine.data('appliancePicker', (data) => appliancePicker(data))
window.Alpine.data('applianceDeliveryRequest', (data) => applianceDeliveryRequest(data))
window.Alpine.data('applianceRequestForm', (data) => applianceRequestForm(data))
window.Alpine.data('applianceSearch', (data) => applianceSearch(data))
window.Alpine.data('searchableDropdown', (data) => searchableDropdown(data))
window.Alpine.data('applianceInspections', (data) => applianceInspections(data))
window.Alpine.data('datePicker', (data) => datePicker(data))
window.Alpine.data('applianceReturnRequest', (data) => applianceReturnRequest(data))
window.Alpine.data('flash', (data) => flash(data))
window.Alpine.data('alpineTable', (data = {}) => alpineTable(data))
window.Alpine.data('passwordToggle', (data) => passwordToggle(data))
window.Alpine.data('tabs', (data = {}) => tabs(data))
window.Alpine.data('userLocations', (data = {}) => userLocations(data))
window.Alpine.data('productGallery', function (data) {
    return productGallery(data);
})
window.Alpine.data('adminUserLocations', function (data) {
    return adminUserLocations(data)
})
window.Alpine.data('user', function (data) {
    return user(data)
})
window.Alpine.data('multiSelect', data => multiSelect(data))
window.Alpine.data('locations', data => locations(data))
window.Alpine.data('pagination', data => pagination(data))
